.staff {
  // padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .all-staff {
    // background: blue;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .modal {
    display: flex;
    flex-direction: column;
    // place-items: center;
    padding: 2rem 1rem;
    justify-content: center;
    align-items: center;
    > h3,
    button {
      text-transform: uppercase;
    }
    .i {
      height: 2rem;
    }
    .select {
      font-size: 1rem;
      width: 200px;
      // box-shadow: ;
    }
    > input {
      width: 200px;
      border-radius: 5px;
      border: 1px solid #ddd;
      padding: 0.5rem 1rem;
      * > {
        padding: 0.5rem 1rem;
      }
      margin: 1rem;
      font-weight: bold;
      box-shadow: 2px 2px 5px 0 #ddd;
      &:focus {
        outline: none;
      }
    }
    > button {
      box-shadow: 2px 2px 5px 0 #ddd;
      border-radius: 5px;
      border: none;
      padding: 0.5rem 1rem;
      font-weight: bold;
      margin: 1rem;
      background-color: var(--primary-color);
    }
  }
  .staffModal {
    width: 250px;
    height: 200px;
    padding: 1rem;
    // padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > i {
      color: hsla(246, 100%, 66%, 0.9);
      align-self: flex-end;
    }
    .i {
      // padding: 0 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      > i {
        margin: 0 0.5rem;
        padding: 0.5rem;
        color: hsla(246, 100%, 66%, 0.9);
        background: hsla(246, 100%, 66%, 0.336);
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
      }
      input {
        // font-family: 'century gothic' !important;
        letter-spacing: 0.5px;
        font-weight: bolder;
        // padding: 0rem 1rem;
        border: none;
        // font-size: 1.2rem;
        text-transform: capitalize;
        flex-grow: 1;
      }
    }

    > button {
      justify-self: flex-end;
      border: 1px solid;
      color: white;
      font-weight: 900;
      letter-spacing: 1px;
      background-color: hsl(246, 100%, 66%);
      width: 200px;
      margin: 0 auto;
      padding: 0.8rem 1rem;
      font-size: 1rem;
      border-radius: 100px;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      -ms-border-radius: 100px;
      -o-border-radius: 100px;
    }
  }
  // background-color: red;
  .current {
    margin: 1rem;
    border-radius: 5px;
    background-color: white;
    padding: 1rem;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.056);
    width: clamp(300px, 50vw, 50%);
    align-self: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }
  .add {
    span {
      background-color: var(--light-primary-color);
      color: var(--primary-color);
      width: 100px;
      height: 100px;
      border-radius: 50%;
      align-self: center;
      justify-self: center;
      box-shadow: 2px 2px 10px 0 #ddd;
      border: 1px solid #ddd;
    }
  }
  .info {
    width: 250px;
    font-weight: bold;
    display: grid;
    text-align: center;
    gap: 1rem;
    h2 {
      text-transform: capitalize;
    }
    .department {
      &.design {
        background: #3f85c655;
        color: #3f85c6;
      }
      &.media {
        background: #c13fc655;
        color: #c13fc6;
      }
      &.autobody {
        background: #5ac63f55;
        color: #5ac63f;
      }
      &.unknown {
        background: #36363623;
        color: #000000;
      }
      border-radius: 2rem;
      padding: 0.6rem 1.2rem;
      margin: auto;
    }
    span i {
      font-size: 5rem;
    }
    i {
      font-size: 1rem;
      // font-size: 1.2rem;
      margin: 0.5rem;
      // scale: 1;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: scale(1.2);
      }
    }
    small {
      color: #aaa;
      letter-spacing: 1px;
    }
  }
  .member {
    margin: 1rem;
    border-radius: 5px;
    background-color: white;
    padding: 1rem;
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.056);

    > .option {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
    }
    // .departments {
    //   display: grid;

    //   // flex-wrap: wrap;
    //   > * {
    //     font-weight: 700;
    //     &.auto_body {
    //       background: #3f85c655;
    //       color: #3f85c6;
    //     }
    //     &.marketing {
    //       background: hsla(60, 54%, 51%, 0.253);
    //       color: hsl(60, 54%, 51%);
    //     }
    //     &.hr {
    //       background: #533fc655;
    //       color: #533fc6;
    //     }
    //     &.design {
    //       background: #c63fa455;
    //       color: #c63fa4;
    //     }
    //     margin: 0.2rem auto;
    //     padding: 0.2rem 1rem;
    //     border-radius: 5rem;
    //   }
    // }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");
.staff-list {
  display: flex;
  flex-direction: column;
  main {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding-block: 1rem;
    // text-align: center;
  }
  .card {
    border: 3px solid var(--primary-color);

    width: 250px;
    display: flex;
    padding: 1rem;
    // flex-basis: 1 0 250px;
    * {
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: flex-end;
    }
    h1 {
      // margin-top: 0.8rem;
      text-transform: capitalize;
      span {
        opacity: 0.3;
      }
    }
    article {
      font-weight: bolder;
      width: 70%;
      // margin-inline: auto;
      span {
        letter-spacing: 1px;
        font-size: 3.3rem;
        // font-family: "Alfa Slab One", cursive;
        -webkit-text-stroke: 4px rgb(0, 0, 0);
        text-transform: uppercase;
        opacity: 0.3;
      }
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
    }
    section {
      .i {
        text-align: center;
        span {
          font-weight: bolder;
        }
        span:nth-child(1) {
          font-size: 3rem;
          margin: 0;
          padding: 0;
          opacity: 0.3;
        }
      }
    }
  }
  .not-working {
    h1 {
      width: 80%;
    }
    section {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &:nth-child(1) {
        width: 80% !important;
      }
      position: relative;
      .i {
        // flex-direction: row;
        span:nth-last-child(1) {
          position: absolute;
          width: 100px;
          bottom: -0.8rem;
          right: -2.5rem;
          opacity: 0.3;
        }
      }
      span:nth-child(1) {
        // text-stroke: 2px red;
        text-stroke: 2px rgb(51, 51, 51);
        -webkit-text-stroke: 2px rgb(51, 51, 51);
      }
    }
  }
}

.job_card {
  display: grid;

  .wrapper {
  }
  .card {
    width: 250px;
    padding: 1rem;
    margin: 1rem;
    height: 400px;
    // border: 1px solid #aaa;
    background: white;
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    .order {
      *:not(:nth-child(1)) {
        display: block;
      }
    }
    > i {
      font-size: 4rem;
    }
    .serame {
      position: absolute;
      bottom: 0;
      width: 300px;
      -webkit-filter: invert(1);
      filter: invert(1);
      opacity: 0.05;
      &.One {
        top: 10rem;
        transform: rotate(90deg);
        left: -10rem;
      }
      &.Two {
        transform: rotate(-90deg);
        top: 10rem;
        // transform: rotate(90deg);

        right: -10rem;
      }
    }
    > * {
      padding: 1rem;
    }
    small {
      font-weight: normal;
    }
    .departments {
      display: flex;
      flex-wrap: wrap;
      > * {
        font-weight: 700;
        &.auto_body {
          background: #3f85c655;
          color: #3f85c6;
        }
        &.marketing {
          background: hsla(60, 54%, 51%, 0.253);
          color: hsl(60, 54%, 51%);
        }
        &.hr {
          background: #533fc655;
          color: #533fc6;
        }
        &.design {
          background: #c63fa455;
          color: #c63fa4;
        }
        margin: 0.2rem;
        padding: 0.2rem 1rem;
        border-radius: 5rem;
      }
    }
  }
}

.pay-form {
  .remaining {
    // border: 1px solid black;
    background-color: var(--light-primary-color);
    color: white;
    border-radius: 50px;
    padding: 0.3rem 1rem;
    position: absolute;
    bottom: 0;
    letter-spacing: 1px;
    left: 0;
    margin: 0.6rem 3rem;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
  }

  .modal {
    padding: 1rem;
    height: 300px;
    width: 250px;
    border-radius: 5px;
    display: grid;
    place-items: center;
    .btn {
      padding: 0.5rem 2rem;
      background: var(--primary-color) !important;
    }

    .center {
      display: grid;
      font-weight: bold;
      i {
        font-size: 10rem;
        color: var(--light-primary-color);
        // color: skyblue;
      }
      div {
        gap: 0.5rem;
        button {
          font-weight: bold;
          text-transform: capitalize;
          background-color: var(--primary-color);
          letter-spacing: 0.5px;
          color: white;
          border: none;
          width: 100%;
          padding: 0.5rem;
          margin: 0.2rem;
          border-radius: 5px;
        }
      }
    }
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .pay-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    background-color: white;

    box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin: 1rem;
    padding: 1rem;

    .list {
      padding: 1rem;
      width: 350px;
      height: 100px;
      .quotecode {
        width: 100%;
        height: 100px;
        font-size: 1.5rem;
        display: grid;
        place-items: center;
        color: rgb(212, 212, 212);
      }
      .quote {
        justify-content: center;
        align-items: center;
        > span {
          font-size: 10rem;
          color: #00000017;
          display: grid;
          place-items: center;
        }
        .titles {
          border-bottom: 2px solid rgba(85, 85, 85, 0.219);
          display: flex;
          flex-wrap: nowrap;
          margin: 0.5rem;
          color: #555;
          * {
            padding: 0.5rem;
            font-weight: bold;
          }
          .name {
            // padding: 0 0.5rem;
            width: 100%;
          }
          .quantity {
            text-align: center;
            width: 5rem;
          }
        }
        .items {
          font-weight: bolder;
          overflow-y: scroll;
          scroll-behavior: smooth;
          max-height: 300px;
          // display: grid;
          gap: 0.5rem;
          padding: 0.5rem;
          border-bottom: 5px solid var(--light-primary-color);
          // background-color: rgb(236, 236, 236);
          .item {
            border: 1px solid #c5c5c55d;
            border-radius: 5px;
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: 1fr;
            box-shadow: 3px 1px 2px 0px rgba(0, 0, 0, 0.2);
            // background-color: white;
            // flex-wrap: nowrap;
            margin-bottom: 0.7rem;
            font-size: 0.8rem;
            .name {
              font-family: 'Nunito', monospace;
              color: #444;
              // font-weight: normal;
              padding: 1rem;
              text-transform: capitalize;
              // padding-right: 0;
            }

            .quantity {
              display: grid;
              justify-content: center;
              align-items: center;
              // padding: 1rem;
              width: 4.5rem;
              border-bottom-right-radius: 5px;
              border-top-right-radius: 5px;
              // padding: auto;
              vertical-align: middle;
              background-color: var(--light-primary-color);
              color: var(--primary-color);
            }
          }
          &::-webkit-scrollbar {
            width: 5px;
          }

          /* Track */

          &::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          /* Handle */

          &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: var(--light-primary-color);
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        }
        @media (max-width: 1150px) {
          display: none;
          overflow: hidden;
        }
      }

      transition: all 2s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    .form {
      min-height: 50vh;
      border: 3px;
      padding: 2rem;
      > .type {
        // position: relative;
        position: fixed;
        bottom: 0;
        right: 0;
        opacity: 0.1;
        font-size: 5rem;
        padding: 2rem;
        font-weight: normal;
      }
      * {
        font-weight: bold;
        align-self: center;
      }
      .wrapper {
        padding: 1rem;
        @media (max-width: 768px) {
          justify-content: center;
        }
      }
      .quotecode {
        color: #ddd;
        font-size: 2rem;
        text-align: center;
      }
      .i {
        display: flex;
        // flex-wrap: wrap;
        align-content: center;
        gap: 1rem;
        margin: 1rem;
        .file {
          input.file-upload-button {
            color: red;
          }
          button {
          }
        }
        > :nth-child(1) {
          text-align: right;
          width: 250px;
          text-transform: capitalize;
        }
        > :nth-child(2) {
          max-width: 300px;
        }
        @media (max-width: 600px) {
          flex-direction: column;
          // text-align: left;
          margin: 0.5rem;
          // padding-left: 2rem;
          gap: 0.5rem;
          // background-color: red;
          // text-align: left;
          > :nth-child(1) {
            // max-width: 200px;
            // background-color: blue;
            text-align: left;
            // align-self: left;
            // margin-bottom: 0.8rem;
          }
          > :nth-child(2) {
            margin-right: auto;
            // max-width: 200px;
            text-align: left;
            margin-bottom: 0.8rem;
          }
        }
        .select {
          width: 200px;
          .css-1pahdxg-control {
            &:hover {
              border-color: var(--light-primary-color);
              box-shadow: 0 0 1px 2px var(--light-primary-color);
              outline: none;
            }
            &:focus {
              outline: none;
              border-color: var(--light-primary-color);
              box-shadow: 0 0 1px 2px var(--light-primary-color);
            }
          }
        }
        .input {
          padding: 0.5rem;
          border-radius: 3px;
          border: 1px solid #eee;
          * {
            border: none;
            &:focus {
              outline: none;
            }
          }
          &.date {
            border: none;
            font-size: 1rem;
            color: #555;
          }
          &.v {
            min-width: 200px;
          }
          &.x {
            min-width: 200px;
          }
          &:focus {
            outline: none;
            box-shadow: 0 0 1px 2px var(--light-primary-color);
          }
        }
      }
      .p {
        border: none;
        padding: 0.5rem 2rem;
        margin: 0.5rem 50%;
        border-radius: 1rem;
        background-color: var(--primary-color);
        color: white;
      }
      > i {
        position: relative;
      }

      .balance {
        display: grid;
        width: 250px;
        padding: 1rem 0;
        margin-left: auto;
        position: relative;
        // border: 1px solid #ddd;
        * {
          text-align: right;
        }
        > input {
          min-width: 200px;
          border: none;
          font-size: 3rem;
          background: transparent;
        }
        @media (min-width: 768px) {
          gap: 0.5rem;
        }
      }
      > .mark {
        // color: red;
        opacity: 0.2;
        bottom: 0;
        right: 0;
        position: fixed;
        font-size: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        > i {
          font-weight: normal;
          padding: 0;
          margin-inline: 1rem;
          font-size: 5rem;
        }
        margin: 1rem;
      }
    }
  }
  // position: relative;
}

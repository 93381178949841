/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');
body,
input {
  font-family: 'Poppins', sans-serif;
} */
.form-container {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr;
  grid-template-columns: 1fr;
  height: 100vh;
  background-color: white;
  font-weight: bold !important;
  span {
    // padding-top: 2rem;
    p {
      font-size: 1.2rem;
    }
    a {
      color: var(--primary-color);
    }
  }
  .giveSpace {
    display: grid;
    // flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
  }

  .form-sign {
    width: 100%;
    /* padding: 0rem 5rem; */
    transition: all 0.2s 0.7s;
    overflow: hidden;
  }

  .title {
    font-size: 2.2rem;
    color: #444;
    margin: 10px 0;
    font-weight: 800;
  }
  p > button {
    background: none;
    border: none;
    font-weight: 900;
    text-align: center;
    color: var(--primary-color);
    margin: auto;
    cursor: pointer;
  }
  p {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .google {
    color: var(--primary-color);
    font-size: 2rem;
  }
}

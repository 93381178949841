.profile {
  display: flex;
  flex-direction: column;
  height: 90vh;
  h1 {
    margin: 1rem 3rem;
  }
  main {
    padding: 1rem 3rem;
    flex-grow: 1;
    background-color: white;
    border-radius: 5px;
    box-shadow: 10px 10px 15px #ddd;
  }
}

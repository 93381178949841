@import url("https://fonts.googleapis.com/css2?family=Barlow&family=Teko:wght@300&family=Patrick+Hand&display=swap");
.PayList {
  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 1rem;
  @media (max-width: 768px) {
    padding-inline: 0.5rem;
    > header {
      justify-content: space-around !important;
    }
  }
  * {
    box-sizing: border-box;

    h2,
    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transition: all 1s ease-in-out;
      -webkit-transition: all 1s ease-in-out;
      -moz-transition: all 1s ease-in-out;
      -ms-transition: all 1s ease-in-out;
      -o-transition: all 1s ease-in-out;
    }
  }
  > header {
    // border: 1px solid #ddd;
    padding: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-content: center;
    justify-content: space-between;
    .button {
      font-size: 1.2rem;
      padding: 1rem 2rem;
      display: grid;
      place-items: center;
      border: none;
      background: var(--primary-color);
      color: white;
      font-family: "century gothic", sans-serif;
      text-transform: capitalize;
      text-decoration-style: none;
      border-radius: 2rem;
      -webkit-border-radius: 2rem;
      -moz-border-radius: 2rem;
      -ms-border-radius: 2rem;
      -o-border-radius: 2rem;
      -moz-text-decoration-style: none;
    }
  }
  > section {
    display: flex;
    // width: 100%;
    flex-direction: column;
    gap: 2rem;
    .i {
      // flex-grow: 1;
      flex-wrap: wrap;
      display: flex;
      gap: 1rem;
      // align-items: center;
      justify-content: center;
      // padding: 1rem;
      // border: 1px solid red;
      > div {
        border-radius: 0.3rem;
        -webkit-border-radius: 0.3rem;
        -moz-border-radius: 0.3rem;
        -ms-border-radius: 0.3rem;
        -o-border-radius: 0.3rem;
        //$color: #eee;
        // &:nth-child(1) {
        //   background-color: rgba(219, 146, 20, 0.1);
        //   color: #ffaa47;
        // }
        &:nth-child(2) {
          background-color: rgba(219, 146, 20, 0.1);
          color: hsl(398, 83%, 47%);
        }
        &:nth-child(3) {
          color: hsl(338, 83%, 47%);
          background-color: hsla(338, 83%, 47%, 0.1);
          // color: hsl(338, 83%, 47%);
        }
        &:nth-child(4) {
          color: hsl(192, 83%, 47%);
          background-color: hsla(192, 83%, 47%, 0.1);
          // background-color: hsl(398, 83%, 47%);
        }
        border: 3px solid;
        width: 150px;
        height: 50px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-weight: bolder;
        i {
          font-size: 2rem;
          margin-inline: 1rem;
        }
      }
    }
    input[type="text"] {
      width: 200px;
      margin-inline: auto;
      padding: 0.5rem 1rem;
      border: none;
      background-color: #eee;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      font-weight: bold;
      letter-spacing: 1px;
      &:focus {
        outline: none;
      }
    }
  }
  main {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 60vh;
    overflow: auto;
    gap: 1rem;
    border: 5px solid #eee;
    border-radius: 7px;
    background-color: #eee;
    padding: 0.1rem 1rem;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    .modal {
      width: 250px;
      padding: 0.5rem;
      height: 250px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      h1 {
        // flex-grow: 1;
        text-align: center;
        // margin
      }
      h3 {
        font-family: "Barlow", sans-serif;
        text-align: center;
        opacity: 0.5;
        margin-bottom: 1rem;
      }
      > div {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;
        overflow-y: auto;
        // flex-grow: 1;
        flex-shrink: 1;
        span {
          text-transform: capitalize;
          letter-spacing: 1px;
          padding: 0.5rem 1rem;
          color: white;
          background-color: var(--primary-color);
          font-weight: 900;
          border-radius: 300px;
          -webkit-border-radius: 300px;
          -moz-border-radius: 300px;
          -ms-border-radius: 300px;
          -o-border-radius: 300px;
        }
      }
    }
    $dark: red;
    $light: red;
    $back: red;
    $white: white;
    .card {
      width: calc(250px - 1rem);
      height: calc(260px - 1rem);
      margin-bottom: 1rem;
      // outline: 3px solid;
      display: flex;
      // overflow: none;
      position: relative;
      .front i.float {
        position: absolute;
        bottom: 1rem;
        right: -5%;
        opacity: 0.2;
        background: none !important;
        // width: 100%;
      }
      &.assigned {
        $dark: black;
        $light: black;
        $back: white;
        * {
          color: $dark !important;
          background-color: white !important;
        }
        .front {
          .stage {
            background-color: black !important;
          }
          .progress {
            background-color: black !important;
          }
        }
        .back {
          button {
            background-color: black !important;
            color: white !important;
            border-radius: 0;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            -ms-border-radius: 0;
            -o-border-radius: 0;
          }
        }
      }

      &.pending {
        $dark: hsl(398, 83%, 47%);
        $back: #fbf4e7;
        * {
          color: hsl(398, 83%, 47%);
          // background-color: #fbf4e7 !important;
        }
        .back {
          h3 {
            color: black;
          }
        }
        .front {
          .stage {
            background-color: $dark;
          }
          .progress {
            background-color: $dark;
          }
        }
        .back {
          button {
            background-color: hsl(398, 83%, 47%);
            color: white;
          }
        }
      }
      &.Done {
        $dark: hsl(192, 83%, 47%);
        $back: #fbf4e7;
        > * {
          color: $dark;
          &:not(.stage) {
            background-color: #e7f9fb !important;
          }
          .stage {
            background-color: $dark;
          }
        }
        .back {
          h3 {
            color: black;
          }
        }
        .front {
          h3 {
            color: black;
          }
          .progress {
            background-color: $dark;
          }
        }
        > .back {
          button {
            background-color: $dark;
            color: $dark;
          }
        }
      }
    }
    .back,
    .front {
      // padding: 1rem;
      border: 3px solid;
      color: $dark;
      flex: 1;
      overflow-y: hidden;
    }
    .back {
      // background-color: crimson;
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
      padding: 0.5rem 1rem;
      background: white;
      h3 {
        border-bottom: 2px solid;
        margin-bottom: 0.5rem;
      }
      h2 {
        font-family: "Teko", sans-serif;
        display: flex;
        gap: 1rem;
        align-items: center;
        letter-spacing: 1px;
        color: black !important;
        span {
          opacity: 0.3;
          font-family: inherit;
        }
      }
      // h2 i {
      //   color: hsl(398, 83%, 47%);
      // }
      button {
        position: absolute;
        bottom: 1rem;
        z-index: 1;
        //border: 1px solid;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border: none;
        padding: 0.2rem;
        align-self: center;
        transition: all 0.5s cubic-bezier(0.5, 0.5, 0.1, 1);
        width: calc(100% - 2.5rem);
        //padding-bottom: 2rem;
        font-size: 1rem;
        font-weight: 900;
        letter-spacing: 1px;
        -webkit-transition: all 0.5s cubic-bezier(0.5, 0.5, 0.1, 1);
        -moz-transition: all 0.5s cubic-bezier(0.5, 0.5, 0.1, 1);
        -ms-transition: all 0.5s cubic-bezier(0.5, 0.5, 0.1, 1);
        -o-transition: all 0.5s cubic-bezier(0.5, 0.5, 0.1, 1);
        &.handler {
          background: white;
          // color: hsl(398, 83%, 47%) !important;
          outline: 3px solid;
        }
      }
    }

    .front > .progress {
      // border: 4px solid #fbf4e7;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0.5rem;
      margin: 0.2rem 0.5rem;
      width: calc(100% - 0.5rem);
      border-radius: 100px;
      transition: all 0.5s ease-in-out;
      background-color: $dark;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      -ms-border-radius: 100px;
      -o-border-radius: 100px;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
    }
    .front {
      z-index: 2;
      box-sizing: border-box;
      position: relative;
      // border: 2px solid hsl(398, 83%, 47%);
      background-color: $back;
      display: flex;
      flex-direction: column;

      h3 {
        color: black !important;
      }
      .payStatus {
        // color: $dark;
        border: 3px solid;
        align-self: center;
        width: 100px;
        text-transform: uppercase;
        font-weight: 900;
        font-family: "Teko", sans-serif;
        text-align: center;
        font-size: 1.5rem;
        opacity: 0.5;
      }
      .stage {
        // jus: flex-end;
        margin-top: auto;
        align-self: center;
        // flex-grow: 1;
        // gap: auto;
        // height:100%;
        text-transform: capitalize;
        letter-spacing: 1px;
        padding: 0.5rem 1rem;
        color: white !important;
        // background-color: $dark;
        text-align: center;
        font-weight: 900;
        border-radius: 300px;
        -webkit-border-radius: 300px;
        -moz-border-radius: 300px;
        -ms-border-radius: 300px;
        -o-border-radius: 300px;
      }
      h3 {
        padding: 1rem;
        font-family: "Teko", sans-serif;
        color: black;
      }
      .head {
        h1 {
          font-family: "Barlow", serif !important ;
        }
        display: flex;
        align-items: center;
        justify-content: center;
        // color: hsl(398, 83%, 47%);
        i {
          color: inherit;
          font-size: 2rem;
          margin-inline-end: 1rem;
        }
      }
      padding: 1rem;
      width: 100%;
      height: 100%;
      position: absolute;
      //top: 0;
      bottom: 0;
      left: 0;

      // background-color: hsl(338, 83%, 47%);
      // background-color: rgb(216, 180, 117);
      // background-color: $back;
      //background-color: hsl(370, 83%, 47%);
      transition: all 0.5s ease-in-out;
      transition-delay: 1s;
      //z-index: 2;
      -webkit-transition: all 0.5s ease-in-out;
      -moz-transition: all 0.5s ease-in-out;
      -ms-transition: all 0.5s ease-in-out;
      -o-transition: all 0.5s ease-in-out;
      outline: none;
      &:focus-within {
        padding: 0.3rem;
        height: 4rem;
        & + .back button {
          bottom: -1.5rem;
        }
      }
    }
  }
}

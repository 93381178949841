.bodyComponent {
  @media (max-width: 768px) {
    padding: 1rem 0.5rem;
    > header {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around !important;
    }
  }
  border: 1.5px solid #eee;
  background-color: white;
  border-radius: 5px;
  min-height: 90vh;
  display: grid;
  width: 100%;
  overflow: none;
  user-select: none;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.0999);
  padding: 2rem 3rem;
  box-sizing: border-box;
  @media (max-width: 800px) {
  }
}

.settings {
  letter-spacing: 1px;
  .title {
    color: transparent;
    font-size: 3rem;
    // -webkit-text-fill-color: white; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgba(0, 0, 0, 0.5);
  }
  .btn {
    letter-spacing: 1px;
  }
  main {
    background-color: white;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    height: 80vh;
    padding: 1rem;
    overflow: auto;
    box-shadow: 5px 5px 10px #ddd;
    border-radius: 5px;
  }
  .i {
    // border: 1px solid black;
    display: grid;
    place-items: center;
    // width: 300px;
    .label {
      font-weight: bold;
      color: #aaa;
      text-transform: uppercase;
      font-size: 0.7rem;
    }
    position: relative;
    i {
      position: absolute;
      right: 0;
      top: 0;
      color: #5995fd;
    }
  }
  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;
  }
  header {
    padding: 1rem 0;
    text-align: center;
    i {
      font-size: 3rem;
    }
  }
  .bankingDetails {
    width: 400px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // outline: 1px solid #5995fd;
    .mask {
      display: none;
      color: white;
      text-shadow: 3px 3px rgb(107, 107, 107);
      // padding: 1rem;
      i {
        font-size: 7rem;
      }
      text-transform: capitalize;
      font-size: 4rem;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: center;
      background-color: skyblue;
      z-index: 1;
    }
  }
  input {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: pointer;
    min-width: 300px;
    max-width: 500px;
    border: none;
    appearance: none;
    background-color: #f2f2f2;
    border-radius: 5px;
    padding: 0.8rem;
    margin: 0.8rem 0;
    outline: none;
    font-weight: bold;
    font-size: 0.9rem;
    letter-spacing: 1.2px;
    text-align: center;
    // color: #f00;
  }
}

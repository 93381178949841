body::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

body::-webkit-scrollbar-track {
  padding: 0.5rem;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  margin: 1rem;
  border-radius: 5rem;
  -webkit-border-radius: 5rem;
  -moz-border-radius: 5rem;
  -ms-border-radius: 5rem;
  -o-border-radius: 5rem;
}
.body,
::before,
::after {
  box-sizing: border-box;
}
.h.img {
  -webkit-filter: invert(1);
  filter: invert(1);
  opacity: 0.15;
  width: 150px;
  height: auto;
}

.children {
  /* content: ''; */
  // display: flex;
  margin: 0 auto;
  padding: 1rem 0;
  // flex-direction: column;
  width: calc(100% - 2rem) !important;
  min-height: calc(100vh - 6rem);
  // overflow: hidden;
  // @media (max-width: 450px) {
  //   // background-color: red;
  //   padding: 0;
  //   margin: 0;
  //   // padding: 0.2rem 0.5rem;
  // }
  // width: 100vw;
  // position: relative;
}
.clients-modal {
  width: 350px;
  height: 300px;
  overflow-y: scroll;
  padding: 1rem;
  > * {
    margin: 0.5rem;
  }
  position: relative;
  > img {
    position: absolute;
    top: -10%;
  }
  .client {
    display: grid;
    // place-items: center;
    justify-content: center;
    align-items: center;
    grid-template-columns: auto 1fr;
    gap: 1rem;
  }
  i {
    font-size: 2rem;
    background-color: var(--primary-color);
    color: white;
    padding: 0.5rem;
    border-radius: 50%;
  }
}

.body {
  position: relative;
  margin: var(--header-height) 0 0 0;
  /* padding: 0 1rem; */
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: 0.5s;
  background-color: var(--white-color);
}
.body a {
  text-decoration: none;
}
/*===== HEADER =====*/
.header {
  box-sizing: border-box;
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: var(--white-color);
  z-index: var(--z-fixed);
  transition: 0.5s;

  box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.1);
  i {
    font-size: 1.5rem;
    color: var(--primary-color);
  }
}
.uid {
  display: none;
  color: var(--primary-color);
}
.header__toggle {
  color: var(--first-color);
  display: flex;
  font-size: 1.5rem;
  cursor: pointer;
  i {
    margin-right: 1rem;
  }
}

.header__img {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}

.header__img img {
  width: 40px;
}

/*===== NAV =====*/
.l-navbar {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: var(--first-color);
  padding: 0.5rem 1rem 0 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
}

.nav {
  // overflow-y: scroll;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  overflow-y: auto;
  /* width */
  &::-webkit-scrollbar {
    width: 1px;
    opacity: 0.5;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: var(--primary-color);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #ddd;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #111;
    width: 3px;
  }
}

.nav__logo,
.nav__link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav__logo {
  margin-bottom: 2rem;
}

.nav__logo-icon {
  font-size: 1.25rem;
  color: var(--white-color);
}

.nav__logo-name {
  color: var(--white-color);
  font-weight: 700;
}

.nav__link {
  position: relative;
  color: #111;
  margin-bottom: 1.5rem;
  transition: 0.3s;
}

.nav__link:hover {
  color: var(--primary-color);
  background-color: var(--white-color);
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  box-shadow: 1px 10px 10px rgba(255, 255, 255, 0.1);
  /* border: 1px dashed red; */
}

.nav__icon {
  font-size: 1.25rem;
}

/*Show navbar movil*/
.show {
  left: 0;
}

/*Add padding body movil*/
.body-pd {
  padding-left: calc(var(--nav-width) + 0.5rem);
}

/*Active links*/
.active {
  box-sizing: border-box;
  color: var(--white-color);
}

.active::before {
  content: '';
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--white-color);
  z-index: -10;
}
.body .div {
  margin: 0;
  padding: 0;
  /* border-right: 1px dashed black; */
}
/* ===== MEDIA QUERIES=====*/
@media screen and (min-width: 768px) {
  .body {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
    padding-left: calc(var(--nav-width) + 0.2rem);
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 1rem);
  }

  .header__img {
    width: 40px;
    height: 40px;
  }

  .header__img img {
    width: 45px;
  }

  .l-navbar {
    left: 0;
    padding: 1rem 1rem 0 0;
  }

  /*Show navbar desktop*/
  .show {
    width: calc(var(--nav-width) + 156px);
  }

  /*Add padding body desktop*/
  .body-pd {
    padding-left: calc(var(--nav-width) + 10.1rem);
  }
  .body-pd .header__toggle {
    padding-left: 2rem;
  }
}

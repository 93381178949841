.InvoiceList {
  box-sizing: border-box;
  grid-template-rows: auto auto 1fr;
  @media (max-width: 768px) {
    padding: 0.5rem 1rem;
  }
  > main {
    //border: 1px dashed #ddd;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    height: 50vh;
    overflow-y: auto;
    background-color: #f7f6fb;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05) inset;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
  }
  > header {
    padding: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .button {
      padding: 1rem;
      font-size: 1.2rem;
      padding: 1rem 2rem;
      display: grid;
      place-items: center;
      border: none;
      background: var(--primary-color);
      color: white;
      font-family: "century gothic", sans-serif;
      text-transform: capitalize;
      text-decoration-style: none;
      border-radius: 2rem;
      -webkit-border-radius: 2rem;
      -moz-border-radius: 2rem;
      -ms-border-radius: 2rem;
      -o-border-radius: 2rem;
      -moz-text-decoration-style: none;
    }
  }
  $light: var(--light-primary-color);
  $dark: var(--primary-color);
  .card {
    position: relative;
    //border: 1px solid #eee;
    background-color: white;
    width: 250px;
    height: 200px;
    display: flex;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    animation: dropIn 0.3s ease-in-out both;
    -webkit-animation: dropIn 0.3s ease-in-out both;
    @keyframes dropIn {
      0% {
        transform: scale(0);
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
      }
      100% {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
      }
    }
    &.fullyPaid {
      --light: #ed859188;
      --dark: #ed8591;
    }
    .items {
      position: absolute;
      top: -0.2rem;
      right: -0.2rem;
      display: grid;
      place-items: center;
      font-weight: 900;
      width: 1.2rem;
      height: 1.2rem;
      color: white;
      font-size: 0.8rem;
      background-color: var(--dark);
      border-radius: 100px;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      -ms-border-radius: 100px;
      -o-border-radius: 100px;
    }
    &.paid {
      --light: #c24ff788;
      --dark: #c24ff7;
    }
    //
    * {
      box-sizing: border-box;
    }
    .bar {
      // pa`dding: 0.5rem;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      width: 3rem;
      background-color: var(--light);
      display: grid;
      place-items: center;
      h1 {
        // background-color: var(--light-primary-color);
        writing-mode: vertical-rl;
        // text-orientation: upright;
        unicode-bidi: bidi-override;
        direction: ltr;
        height: 100%;
        // text-align: center;
        padding-top: 1rem;
        font-size: 1.5rem;
        color: white;
        // text-shadow: 2px 2px $dark;
        // margin: auto 0;
      }
      position: relative;
      > i {
        position: absolute;
        bottom: 0.5rem;
        text-align: center;
        font-size: 2rem;
        // padding-left: auto;
        // right: 0;
        color: var(--dark);
      }
    }
    section {
      padding: 0.5rem 1rem;
      // border: 1px solid #eee;
      flex-basis: 80%;
      display: flex;
      flex-direction: column;
    }
    .tag {
      // border: 1px solid #ddd;
      padding: 0.3rem 0.2rem;
      font-size: 9pt;
      border-radius: 100px;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      -ms-border-radius: 100px;
      -o-border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      // flex-shrink: shrink;
      font-weight: 900;
      flex-shrink: 1;
      i {
        display: grid;
        place-items: center;
        font-size: 5pt;
        padding-right: 0.3rem;
      }
    }
    .button {
      &:nth-last-child(2) {
        margin-top: auto;
      }
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      // display: block;
      width: 90%;
      margin: 0 auto;
      margin-bottom: 0.5rem;
      background-color: var(--light);
      letter-spacing: 1px;
      color: white;
      // font-weight: 900;
      border: none;
      border-radius: 100px;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      -ms-border-radius: 100px;
      -o-border-radius: 100px;
      // display: grid;
      // place-items: center;
      div {
        flex: 0.5;
        padding: 0.3rem;
        display: grid;
        place-items: center;
        i {
          font-size: 1.1rem;
        }
      }
      div:nth-child(1) {
        background-color: var(--dark);
      }
      div:nth-child(2) {
        color: var(--dark);
      }
      span {
        padding: 0.5rem;
        // color: var(--dark);
        font-size: 0.7rem;
        font-family: "century gothic", monospace;
        text-transform: uppercase;
        font-weight: 900 !important;
        // text-align: center;
      }
    }
    > .out {
      position: absolute;
      bottom: -1.5rem;
      left: 0.5rem;
    }
    > .i {
      text-align: center;
      background-color: rgb(231, 231, 231);
      padding: 0.1rem 1rem;
      left: 5rem;
      font-size: 0.8rem;
      font-weight: 900;
      color: #fff;
      border-radius: 100px;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      -ms-border-radius: 100px;
      -o-border-radius: 100px;
    }
  }
  > section {
    text-align: center;
    max-width: 600px !important;
    margin: 0 auto;
    .i {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      justify-content: center;
      > * {
        margin: 1rem;
        font-weight: 900;
      }
      // &.deposit {
      // }
      // &.paid {
      // }
      .deposit {
        $light: #c24ff754;
        $dark: #c24ff7;
        width: 100px;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
        padding: 0.5rem 2rem;
        //border: 2px solid blue;
        transform-origin: top center;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        //
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        &:before {
          transform-origin: top center;
          transition: all 0.5s ease-in-out;
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          -ms-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;
          content: "";
          z-index: -1;
          border: 2px solid $dark;
          background-color: $dark;
          //margin-bottom: 0.5rem;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          height: 10%;
          border-radius: 7px;
          -webkit-border-radius: 7px;
          -moz-border-radius: 7px;
          -ms-border-radius: 7px;
          -o-border-radius: 7px;
        }
        // margin: 0 auto;
        // text-align: center;
        &:hover:before {
          height: 100%;
        }
      }
      .paid {
        $light: #ed859154;
        $dark: #ed8591;
        width: 100px;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
        padding: 0.5rem 2rem;
        // border: 2px solid blue;
        transform-origin: top center;
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        //
        &:before {
          transform-origin: top center;
          transition: all 0.5s ease-in-out;
          -webkit-transition: all 0.5s ease-in-out;
          -moz-transition: all 0.5s ease-in-out;
          -ms-transition: all 0.5s ease-in-out;
          -o-transition: all 0.5s ease-in-out;
          content: "";
          z-index: -1;
          border: 2px solid $dark;
          background-color: $dark;
          // margin-bottom: 0.5rem;
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          height: 10%;
        }
        // margin: 0 auto;
        // text-align: center;
        &:hover:before {
          height: 100%;
        }
      }
    }
    input[type="text"] {
      padding: 0.5rem 1rem;
      border: none;
      background-color: #eee;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
      font-weight: bold;
      letter-spacing: 1px;
      &:focus {
        outline: none;
      }
    }
  }
}

.error-page {
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: calc(100vh - var(--header-height) - 2rem);

  /*  */
  color: white !important;
  background-color: var(--primary-color);
  display: grid;
  place-items: center;
}

.quoteLis {
  // border: 1.5px solid #eee;
  // background-color: white;
  // border-radius: 5px;
  // min-height: 90vh;
  // display: grid;
  // width: 100%;
  // overflow: none;
  // user-select: none;
  // box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.0999);
  .search {
    // background-color: red;
    text-align: center;
    input {
      padding: 0.3rem 0.7rem;
      border: 1px solid #ddd;
      border-radius: 5px;
      font-weight: bold;
      font-family: "century gothic";
      color: rgb(180, 180, 180);
      transition: all 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      -webkit-transition: all 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      -moz-transition: all 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      -ms-transition: all 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      -o-transition: all 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      &:focus {
        outline: none;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      }
    }
  }
  .quoteModal {
    padding: 1rem;
    width: 300px;
    height: 300px;
    position: relative;
    font-family: "century gothic" !important;
    display: flex;
    flex-direction: column;

    h1 {
      text-align: center;
      padding: 1rem;
      font: 2.2rem "century gothic";
    }
    .item {
      // border: 1px solid #ddd;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: space-between;
      // font-weight: normal;
    }
    > .button {
      font-family: "century gothic";
      align-self: center;
      padding: 0.4rem 1rem;
      background: var(--primary-color);
      color: white;
      // text-transform: uppercase;
      // letter-spacing: 1px;
      border: 1px solid #ddd;
      border-radius: 100px !important;
      -webkit-border-radius: 100px !important;
      -moz-border-radius: 100px !important;
      -ms-border-radius: 100px !important;
      -o-border-radius: 100px !important;
    }
    footer {
      width: calc(100% - 2rem);
      display: flex;
      justify-content: space-between;
      align-items: center;
      // padding: 1rem;
      padding: 1rem;
      font-size: 1.5rem;
      i {
        font-size: 3rem;
      }
      color: #ddd;
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
  .modal {
    padding: 1rem;
    border-radius: 5px;
    text-align: center;
    min-width: 250px;
    h2 {
      color: #ddd;
      font-size: 2rem;
    }
    .center {
      color: #ddd;
      // color: black;
      padding: 1rem;
      display: flex;
      justify-content: center;
      .span {
        color: black;
        // opacity: 0.8;
        padding: 1rem;
        font-size: 1.2rem;
        i {
          font-size: 5rem;
        }
      }
    }
  }
  main {
    display: grid;
    grid-template-rows: auto auto 1fr;
    > header {
      // border: 1px solid #ddd;
      padding: 1rem 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .button {
        font-size: 1.2rem;
        padding: 1rem 2rem;
        display: grid;
        place-items: center;
        border: none;
        background: var(--primary-color);
        color: white;
        font-family: "century gothic", sans-serif;
        text-transform: capitalize;
        text-decoration-style: none;
        border-radius: 2rem;
        -webkit-border-radius: 2rem;
        -moz-border-radius: 2rem;
        -ms-border-radius: 2rem;
        -o-border-radius: 2rem;
        -moz-text-decoration-style: none;
      }
    }
  }
  section {
    $pending: var(--light-primary-color);
    $dark_pending: var(--primary-color);
    $approved: darken(skyblue, 0%);
    $overdue: lighten(tomato, 10%);
    .cards {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      padding: 1rem 0;
      overflow-wrap: break-word;
      *:not(i) {
        border-radius: 5px;
        font-weight: bold;
        // background-color: red;
        h1 {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .pending {
        border: 2px solid $pending;
        color: $pending;
        &.card {
          border: 2px solid $pending;
          background: $pending;
        }
      }
      .approved {
        border: 2px solid $approved;
        color: $approved;
        &.card {
          border: 2px solid $approved;
          background: $approved;
        }
      }
      .overdue {
        border: 2px solid $overdue;
        color: $overdue;
        &.card {
          border: 2px solid $overdue;
          background: $overdue;
        }
      }
      .item {
        color: black;
        span:nth-last-child(1) {
          padding: 0.5rem;
          margin: 0 0.5rem;
          // background: var(--light-primary-color);
          color: var(--primary-color);
        }
      }
      .card {
        width: 200px;
        height: 160px;
        margin: 0.8rem;
        padding: 0.5rem;
        position: relative;
        box-shadow: 5px 5px 5px 0 #00000019;
        transition: all 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        * {
          transition: all 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        }
        .by {
          transition-delay: 2s;
        }
        .companyType {
          display: block;
          i {
            // position: relative;
            font-size: 3rem;
          }
        }
        &:focus-within {
          outline: none;
          width: 300px;
          .tab {
            width: 70px;
          }
          .by {
            transform: scale(1);
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            -o-transform: scale(1);
          }
          .a > i {
            display: block;
          }
        }
        .status {
          float: right;
          padding: 0.6rem 0;
          text-transform: uppercase;
          font-weight: bold;
          writing-mode: vertical-rl;
          color: white;
        }
        .by {
          color: white;
          letter-spacing: 0.5px;
          height: calc(100% - 2rem);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 0.5rem 1.5rem;
          margin-left: 4rem;
          transition: all 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
          -webkit-transition: all 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
          -moz-transition: all 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
          -ms-transition: all 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
          -o-transition: all 1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
          transform: scale(0);
          -webkit-transform: scale(0);
          -moz-transform: scale(0);
          -ms-transform: scale(0);
          -o-transform: scale(0);
          button {
            letter-spacing: 1px;
            // text-transform: uppercase;
            // opacity: 0;
            padding: 0.5rem 1rem;
            border-radius: 2rem;
            // border: 3px solid white;
            border: none;
            background: white;
            font-family: "century gothic";
            // color: var(--light-primary-color);
            color: rgb(134, 134, 134);
            font-size: 0.8rem;
            font-weight: bold;
            // background: none;
          }
        }

        .tab {
          padding: 0.8rem;
          margin: 1rem;
          position: absolute;
          background-color: white;
          width: 160px;
          height: calc(100% - 3.5rem - 2px);
          top: 0;
          left: -2rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          > i {
            position: absolute;
            right: 0;
            margin: 0 0.5rem;
            font-weight: normal;
          }
          .button {
            padding: 0.5rem 1rem;
            border-radius: 2rem;
            font-size: 0.8rem;
            width: 150px;
            .a {
              display: none;
            }
          }
        }
      }
    }
    ._cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      // padding: 1rem;
      * {
        margin: 1rem;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
      }
      .card {
        padding: 1rem;
        width: 150px;
        height: 30px;
        position: relative;
        &.pending {
          .tab {
            color: $pending;
            border: 2px solid $pending;
          }
          background-color: $pending;
        }
        &.approved {
          .tab {
            color: $approved;
            border: 2px solid $approved;
          }
          background-color: $approved;
        }
        &.overdue {
          .tab {
            color: $overdue;
            border: 2px solid $overdue;
          }
          background-color: $overdue;
        }
        .tab {
          position: absolute;
          background-color: white;
          width: 100%;
          height: 100%;
          top: -1rem;
          left: -2rem;
          font-weight: bolder;

          i {
            position: absolute;
            bottom: -0.5rem;
            right: -0.51rem;
            opacity: 0.3;
            font-size: 3rem;
          }
        }
      }
    }
    padding: 1rem 0;
  }
}

.JobList {
  > * {
  }
  display: grid;
  grid-template-rows: auto auto 1fr;
  input {
    background-color: #eee;
    border: none;
    width: 150px;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    outline: none;
    font-weight: 900;
    margin: 2rem auto;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }
  main {
    display: flex;
    // flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: clamp(250px, 40vw, 60%);
    // height: clamp(250px);
    // aspect-ratio: 16/9;
    gap: 1rem;
    > div {
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      -webkit-transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      -moz-transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      -ms-transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      -o-transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      &:hover {
        background-color: rgba(255, 140, 0, 0.13);
      }
      padding: 1rem;
      align-items: center;
      border: 3px solid darkorange;
      gap: 1rem;
      display: flex;
      flex-wrap: wrap;
      //flex-direction: column;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      &.Marketing {
        $color: #ea61fc;
        $light-color: #ea61fc21;
        border: 3px solid $color;
        &:hover {
          background-color: $light-color !important;
        }
        .text {
          background-color: $color;
        }
        .last {
          i {
            font-size: 1.5rem;
            color: $color;
          }
        }
        p {
          // min-width: 300px;
          border-bottom: 4px solid $light-color;
          color: $color;
        }
        button {
          background-color: $color;
        }
      }
    }
    &.Design {
      $color: #7861fc;
      $light-color: #7861fc21;
      border: 3px solid $color;
      &:hover {
        background-color: $light-color !important;
      }
      .text {
        background-color: $color;
      }
      .last {
        i {
          font-size: 1.5rem;
          color: $color;
        }
      }
      p {
        // min-width: 300px;
        border-bottom: 4px solid $light-color;
        color: $color;
      }
      button {
        background-color: $color;
      }
    }
    .first {
      display: grid;
      gap: 0.5rem;
      width: 250px;
    }
    .text {
      background-color: darkorange;
      // font-family: "source pro", Tahoma, Geneva, Verdana, sans-serif;
      font-weight: bold;
      // margin-inline: 0.5rem;
      color: #fff;
      padding: 0.5rem;

      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;
    }
    .head {
      display: flex;
      gap: 0.5em;
      align-items: center;
      padding-top: 0.5rem;
      p {
        opacity: 0.5;
        font-weight: 900;
      }
      span {
        &:nth-child(4n + 1) {
          background-color: darkorange;
        }
        &:nth-child(4n + 2) {
          background-color: gold;
        }
        &:nth-child(4n + 3) {
          background-color: crimson;
        }
        &:nth-child(4n + 4) {
          background-color: darkorange;
        }
        font-size: 0.8rem;
        padding: 0.3rem 1rem;
        border: none;
        color: white;
        font-weight: 900;
        letter-spacing: 1px;
        border-radius: 100px;
        -webkit-border-radius: 100px;
        -moz-border-radius: 100px;
        -ms-border-radius: 100px;
        -o-border-radius: 100px;
      }
    }
    .last {
      i {
        font-size: 1.5rem;
        color: darkorange;
      }
      display: flex;
      align-items: center;
      gap: 1em;
      margin-left: auto;
      @media (max-width: 700px) {
        padding: 0.5rem;
      }
    }
    .middle {
      // background-color: rgba(255, 140, 0, 0.171);
      display: flex;
      // place-items: center;
      // height: 100%;
      width: 300px;
      p {
        // min-width: 300px;
        border-bottom: 4px solid rgba(255, 140, 0, 0.171);
        padding: 0.2rem;
        color: darkorange;
      }
      font-weight: 900;
    }
    button {
      background-color: darkorange;
      padding: 0.5rem 1rem;
      border: none;
      color: white;
      font-weight: 900;
      letter-spacing: 1px;
      border-radius: 100px;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      -ms-border-radius: 100px;
      -o-border-radius: 100px;
    }
  }
}

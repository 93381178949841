.jobs {
  // background: white;
  height: 85vh;
  padding: 1rem 3.5rem;
  display: flex;
  flex-direction: column;
  > section {
    background: white;
    border-radius: 5px;
    border: #dddddd51 1px solid;
    box-shadow: 10px 10px 10px #dddddd6d;
    flex-grow: 1;
    padding: 1rem;
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;

    > * {
      border: #dddddd 1px dashed;
      padding: 1rem;
      margin: 0 0.3rem;
    }
    .list {
      width: 250px;
      display: flex;
      flex-direction: column;
    }

    .divider {
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding: 0.5rem;
      // box-shadow: 0 10px 10px 10px rgb(75, 75, 75);
      i {
        font-size: 2rem;
        margin-right: 0.5rem;
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
      }
    }
    .box {
      overflow: auto;
      padding: 0.5rem;
      // border: #dddddd 1px dashed;
      background-color: #f7f6fb;
      flex-grow: 1;
      height: 50vh;
    }
    .tab {
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      // border: #dddddd 1px dashed;
      background: white;
      height: 100px;
      border-radius: 5px;
      box-shadow: 2px 2px 10px #ddd;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > section {
        // border: #dddddd 1px dashed;
        // padding: 0.3rem 0;
        > div {
          padding: 0.1rem 0;
          font-weight: bold;
        }
      }
      // color: #ddd;
      h4 {
        text-transform: uppercase;
      }
      > i {
        position: absolute;
        bottom: 0;
        right: 0;
        opacity: 0.1;
        font-size: 3rem;
        margin: 0.3rem;
      }
    }
    .order {
      border: 1px solid #ddd;
      position: relative;
      padding: 0.5rem;
      i {
        // margin: 0.5rem;
        position: absolute;
        bottom: 1.5rem;
        right: 1.5rem;
        font-size: 2rem;
        opacity: 0.1;
      }
      min-height: 3rem;
    }
    .people {
      border: 1px solid #ddd;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
    }
    main {
      flex-grow: 1;
      min-width: 300px;
      // .order {
      //   border-top: 1px solid #ddd;
      // }
    }
    textarea {
      padding: 0.5rem;
      width: calc(100% - 1rem);
    }
    button {
      width: 100%;
      border: none;
      padding: 0.5rem;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

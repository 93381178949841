.btn-solid {
  width: 150px;
  background-color: var(--primary-color);
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
  text-align: center;
}

.btn-solid:hover {
  background-color: #75a334;
}

.loading {
  display: grid;
  place-items: center;
  min-height: 100vh;
  background-color: white;
  > i {
    font-weight: lighter;
    animation: rotate 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite
      forwards;
    -webkit-animation: rotate 1s cubic-bezier(0.175, 0.885, 0.32, 1.275)
      infinite forwards;
    color: #0f3d60;
  }
  .v_divide {
    display: grid;
    gap: 2rem;
    i {
      color: #0f3d60;
      font-weight: lighter;
      font-size: 2rem;
    }
    h2 {
      color: #0f3d6055;
    }
  }
  @keyframes rotate {
    from {
      font-size: 4rem;
    }
    to {
      font-size: 5rem;
      transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
    }
  }
}
* {
  -moz-osx-font-smoothing: always;
  .pickClientModal {
    width: 300px;
    *:not(i) {
      font-weight: 900;
    }
    p,
    h1 {
      text-align: center;
    }
    header {
      padding: 1rem;
    }
    .items {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      overflow-y: auto;
      height: 30rem;
      padding: 1rem;
      background-color: #0f3d6011;
    }
    .item {
      > i {
        font-size: 2rem;
        color: #0f3d6022;
      }
      small {
        color: #0f3d6055;
      }
      display: flex;
      gap: 0.6rem;
      padding: 0.2rem 1rem;
      //justify-content: space-between;
      align-items: center;
      color: #0f3d60;
      background-color: #0f3d6022;
      font-weight: 900;
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
    }
    footer {
      display: flex;
      justify-content: space-around;
      place-items: center;
      span {
        padding-block: 0.5rem;
        > i {
          font-size: 2rem;
          color: #0f3d60;
        }
        display: grid;
        place-items: center;
        text-transform: uppercase;
      }
    }
  }
}

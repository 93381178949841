.client-pay {
  border: 1px dashed #ddd;
  height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  h1 {
    text-transform: capitalize;
    margin: 0.5rem 0;
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  // border: 1px dashed skyblue;
  main {
    padding: 0.5rem;
    flex-grow: 1;
    background: white;
    border-radius: 5px;
    box-shadow: 5px 5px 15px #ddd;
  }
  .big {
    padding: 5rem;
    background: #ff6347;
  }
  .small {
    padding: 5rem;
    background: #47a6ff;
    // background-blend-mode: color(green);
  }
  > section {
    // background: #ff6347;
    display: flex;
    flex-direction: column;
    // height: 100vh;
    // flex-grow: 1;
  }
  .file {
    outline: 1px solid hsl(30, 100%, 64%);
    i {
      font-size: 4rem;
    }
  }
  .search {
    outline: 1px solid hsl(30, 100%, 64%);
    input {
      border: none;
      &::placeholder {
        text-transform: capitalize;
      }
    }
  }
  .list {
    outline: 1px solid hsl(30, 100%, 64%);
    flex-grow: 1;
  }
  @media screen and (min-width: 1224px) {
    // isBigScreen
    padding: 0.5rem 3rem;
    main {
      outline: 1px solid hsl(30, 100%, 64%);
    }
  }
  @media screen and (max-width: 1224px) {
    // isTabletOrMobile
    width: 100%;
    padding: 0;
    margin: 0 auto;
    h1 {
      margin: 0 auto;
    }
    main {
      outline: 1px solid hsl(20, 100%, 64%);
    }
  }
}

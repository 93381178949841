.clients-list {
  // overflow: hidden;
  // overflow-y: scroll;
  // width: 100%;
  display: flex;
  flex-direction: column;
  // // grid-template-rows: auto 1fr;
  max-height: 89vh;
  // background-color: red;
  // position: relative;
  .modal {
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    width: clamp(250px, 300px, 400px);
    padding: 1rem;
    text-align: center;

    .type {
      a {
        color: black !important;
      }
      i {
        font-size: 5rem;
        // margin: 0 0.5rem;
      }
      margin: 1rem 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
  .search {
    // background-color: orange;

    input {
      padding: 1rem 2rem;
      border: none;
      font-weight: bold;
      letter-spacing: 1px;
      &:focus {
        outline: none;
      }
    }
  }
  .pick-client {
    // background-color: purple;
    flex-basis: 100%;
    display: flex;
    // // flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    overflow-y: auto;
    // height: 100%;
    a {
      color: var(--primary-color);
    }
    .client {
      position: relative;
      padding: 1rem;
      margin: 0.5rem;
      box-shadow: 5px 5px 15px 2px rgba(0, 0, 0, 0.051);
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      background-color: white;
      border-radius: 5px;
      height: 170px;
      width: 250px;
      .options {
        i {
          display: grid;
          place-items: center;
          position: absolute;
          top: 0;
          right: 0;
          background-color: #8dc63f55;
          border-radius: 50%;
          padding: 0.3rem auto;
          width: 30px;
          height: 30px;
        }
      }
      > i {
        height: 50px;
        width: 50px;
        font-size: 3rem;
        padding: 1.5rem;
        background-color: #8dc63f55;
        color: var(--primary-color);
        // background-color: #87cfeb57;
        // color: #87cfeb;
        border-radius: 50%;
      }
      .data {
        display: grid;
        gap: 0.3rem;
        small {
          color: #aaa;
          text-overflow: ellipsis;
        }
      }
    }
  }
  > .search {
    margin: 1rem;
    text-align: center;
  }
  > .staff {
    margin: 1rem;
    width: 250px;
    // border: 1px solid #aaa;
    border-radius: 5px;
    background-color: white;
    padding: 1rem;
    box-shadow: 0 10px 10px 2px rgba(170, 170, 170, 0.205);
    .info {
      font-weight: bold;
      display: grid;
      // place-items: center;
      text-align: center;
      // gap: 1rem;
      // justify-content: center;
      // align-items: center;
      gap: 1rem;
      .department {
        background: #8dc63f55;
        color: var(--primary-color);
        border-radius: 2rem;
        padding: 0.6rem 1.2rem;
        margin: auto;
      }
      span i {
        font-size: 5rem;
      }
      i {
        font-size: 1.5rem;
        // font-size: 1.2rem;
        margin: 0.5rem;
        // scale: 1;
        transition: all 0.3s ease-in-out;
        &:hover {
          transform: scale(1.2);
        }
      }
      small {
        color: #aaa;
      }
    }
    > .option {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;
    }
    .departments {
      display: grid;

      // flex-wrap: wrap;
      > * {
        font-weight: 700;
        &.auto_body {
          background: #3f85c655;
          color: #3f85c6;
        }
        &.marketing {
          background: hsla(60, 54%, 51%, 0.253);
          color: hsl(60, 54%, 51%);
        }
        &.hr {
          background: #533fc655;
          color: #533fc6;
        }
        &.design {
          background: #c63fa455;
          color: #c63fa4;
        }
        margin: 0.2rem auto;
        padding: 0.2rem 1rem;
        border-radius: 5rem;
      }
    }
  }
}

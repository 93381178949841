.quote_no_client {
  * {
    font-weight: bold;
    text-align: center;
    user-select: none;
    cursor: pointer;
  }
  display: grid;
  place-items: center;
  min-height: 80vh;
  i {
    font-weight: normal;
    font-size: 15em;
    color: var(--light-primary-color);
    transition: all 3s ease-in-out;
  }
  button {
    text-transform: uppercase;
    color: black;
    background-color: var(--primary-color);
    padding: 0.6rem 2rem;
    margin: 2rem;
    border: none;
    border-radius: 3px;
  }
}
.client-form {
  * {
    user-select: none;
    cursor: pointer;
  }
  width: 100%;
  // min-height: 100vh;
  font-family: 'Lato', sans-serif;
  display: grid;
  // grid-template-columns: 1fr;
  .undone {
    box-shadow: 0px 0px 1px 2px rgba(255, 0, 0, 0.5);
    // border: 1px solid red !important;
    background-color: white;
  }
  .modal {
    padding: 1rem;
    width: 300px;
    display: grid;
    place-items: center;
    i {
      font-size: 10rem;
      margin: 1rem auto;
      color: var(--primary-color);
      opacity: 0.9;
    }
    button {
      border: none !important;
      padding: 0.5rem 1rem;
      margin: 0.5rem 1rem;
      font-size: 1rem;
      font-weight: bold;
      color: var(--white-color);
      background-color: var(--primary-color);
      border-radius: 2rem;
      letter-spacing: 1px;
    }
    .decide {
      display: grid;
      place-items: center;
    }
  }
  .c-head {
    padding: 1rem;
    margin: 1rem;
    font-size: 2rem;
  }

  .bx {
    cursor: pointer;
    font-weight: bold;
    font-size: 1rem;
    &.bx-trash-alt:hover {
      color: red;
    }
    &.bx-plus:hover {
      color: var(--primary-color);
    }
    &.bx-trash-alt:before {
      content: '\ec13';
    }
  }

  /*   .quote-container {
    margin: auto;
    padding: 0px 20px;
  } */
  // text-align: center;
  .MakeQuote {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
  }
  .quote-container {
    display: grid;
    place-items: center;
    max-width: 1200px;
    margin: 0 auto;
    // background-color: red;
  }
  .quote-header,
  .quote-footer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    padding: 2rem;
    width: calc(80% - 4rem);

    align-items: flex-end;
    font-weight: 900;
  }
  .undoneMessage {
    margin: 0 auto;
    text-align: center !important;
    color: #aaa !important;
    font-style: italic;

    // margin-top: 1rem;
    width: calc(80% - 2rem);
  }
  .quote-footer {
    display: flex;
    // flex-direction: column;
    .q-btn:hover {
      background-color: white;
      color: var(--primary-color);
      box-shadow: none;
      border: 4px solid var(--primary-color);
    }
    .q-btn {
      margin: 0 auto;
      font-weight: 900;
      color: white;
      font-size: 1.2rem;
      color: white;
      background-color: var(--primary-color);
      padding: 1rem 3rem;
      border-radius: 5rem;
      border: 4px solid var(--primary-color);
      transition: color 0.3s ease;

      box-shadow: 0px 1px 10px 1px rgba(0, 0, 0, 0.3);
    }
  }
  .quote-body {
    width: 80%;
    padding: 1rem;
    background-color: white;
    box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  .title-date {
    width: 100%;
  }
  .title-q {
    font-family: 'Roboto mono medium', sans-serif;
    font-size: 3rem;
    letter-spacing: 3px;
    color: rgb(66, 66, 66);
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .date {
    font-size: 1rem;
    letter-spacing: 3px;
    color: rgb(156, 156, 156);
    display: grid;
    // margin: 0 2rem;
    width: 300px;
    box-sizing: border-box;
    .due {
      // span {
      //   width: 10px;
      // }
      padding-top: 0.8rem;
      input {
        padding: 0rem;
        margin: 0rem;
        text-align: right;
        float: right;
        border: none;
        font-size: 1rem;
        letter-spacing: 2px;
        text-align: right;
        color: #bbb;
        font-weight: 900;
        background-color: transparent;
      }
      input:focus {
        outline: none;
      }
    }
  }

  .quote-number {
    font-size: 17px;
    letter-spacing: 2px;
    color: rgb(156, 156, 156);
    text-align: right;
  }

  /*  .space {
    width: 50%;
  } */

  table {
    table-layout: auto;
    width: 100%;
  }
  th {
    // border-bottom: 1px dashed rgb(187, 187, 187);
    padding: 10px 0px;
    font-weight: 900;
    font-size: 1rem;
    letter-spacing: 2px;
    color: #aaa;
    text-align: center;
    &:nth-child(1) {
      text-align: left;
    }
    &:nth-last-child(1) {
      // border-bottom: 1px dashed rgb(187, 187, 187) !important;
    }
  }
  .single-row {
    border-bottom: 0.5px solid rgb(226, 226, 226);
    &:nth-child(1) {
      padding-right: 0;
      // width: 90%;
    }
    .m {
      // display: none;
      position: absolute;
      padding: 2rem;
      background-color: white;
      width: 300px;
    }
  }
  td {
    padding: 0.5rem 0;
    text-align: center;
    &.product-name {
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      input {
        margin: 0 1rem;
        text-align: center;
      }
    }
  }
  tr {
    &:nth-last-child(1) {
      border: none;
    }
  }
  .dashed {
    border-bottom-style: dashed;
  }

  .total {
    font-weight: 800;
    font-size: 20px !important;
    color: black;
  }

  input[type='number'] {
    text-align: center;
    max-width: 50px;
    font-size: 15px;
    padding: 10px;
    border: none;
    outline: none;
  }
  input {
    font-weight: 900;
    border-radius: 5px;
    background-color: #f2f2f2;
    &.price.undefined {
      background: var(--primary-color);
      // color: var(--primary-color);
      &::before {
      }
    }
  }
  .undefined::placeholder {
    // content: '?';
    color: black;
  }
  input[type='text'] {
    max-width: 100% !important;
    text-align: left;
    font-size: 15px;
    padding: 10px;
    border: none;
    outline: none;
  }
  input:focus::placeholder {
    color: transparent;
  }
  input::placeholder {
    color: #aaa;
    transition: color 0.3s;
  }

  /*Hide Arrows From Input Number*/
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .float {
    width: 40px;
    height: 40px;
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 100%;
    text-align: center;
    display: grid;
    place-items: center;
  }
  #add-row {
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.2);
  }

  .plus {
    margin-top: 10px;
  }

  #sum {
    text-align: right;
    width: 90%;
    h1 {
      display: inline-block;
    }
  }

  #sum > input {
    // width: 100%;
    font-size: 33px !important;
    color: black;
    text-align: right !important;
    background-color: transparent;
  }

  @media all and (max-width: 950px) {
    .MakeQuote {
      grid-template-columns: 1fr;
    }
    .quote-header {
      grid-template-columns: 1fr;
      * {
        text-align: center;
      }
    }
    .quote-body {
      // display: grid;
      place-items: center;
      background: none;
      box-shadow: none;
      // display: grid;
    }
    .date {
      justify-content: space-around;
      margin: auto;
    }
    table {
      width: 100%;
    }
    thead {
      display: none;
    }
    tbody {
      // background-color: #aaa;
      display: grid;
      grid-template-columns: 1fr;
      td {
        border: none;
        // gap: 1rem;
        width: 100%;
        margin: 0 auto;
      }
      .single-row {
        background-color: white;
        // box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        margin-bottom: 1rem;
        padding: 2rem 0;
        width: 100%;
        .product-name {
          width: 90% !important;
          margin: 1rem auto;
        }
        * ~ :not(datalist) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        * {
          &:before {
            // padding: 10px 0px;
            padding-bottom: 0.5rem;
            font-weight: 900;
            font-size: 1rem;
            letter-spacing: 2px;
            color: #aaa;
            content: attr(data-label);
            flex-basis: 100%;
            text-align: center;
          }
        }
      }
      tr {
        * {
          text-align: center;
        }
      }
    }
    #sum {
      width: 90%;
    }
  }
  table,
  th,
  td {
    // border-collapse: collapse;
  }
}

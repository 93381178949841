.UserList {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: clamp(250px, 100%, 1200px);
  margin: 0 auto;
  * {
    box-sizing: border-box;
  }
  @media (max-width: 768px) {
    padding: 0.5rem 0.8rem;
  }
  header {
    i {
      font-size: 2rem;
    }
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    align-content: center;
    // padding: 1rem;
    // width: 100px;
    // place-items: center;
    input {
      background-color: #eee;
      border: none;
      outline: none;
      font-size: 1rem;
      padding: 0.5rem 0.8rem;
      font-weight: bold;
      letter-spacing: 1px;
      border-radius: 3px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -ms-border-radius: 3px;
      -o-border-radius: 3px;
    }
    > div {
      gap: 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
    span {
      &:nth-child(1) {
        color: rgb(255, 145, 71);
      }
      &:nth-child(2) {
        color: rgb(255, 71, 132);
        flex-direction: row-reverse;
      }
      width: 150px;
      display: flex;
      text-transform: capitalize;
      font-weight: bold;
      gap: 1rem;
      place-items: center;
      // border: 1px dashed;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 60vh;
    // width: clamp(150px, 100%, 950vw);
    overflow-y: auto;
  }
  ul li {
    list-style-type: none;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    // flex-direction: column;
    justify-content: space-between;
    align-items: center;
    span {
    }
    span:nth-last-child(1) {
      opacity: 0.5;
    }
    transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
  }
  li:hover {
    filter: drop-shadow(1.5);
    -webkit-filter: drop-shadow(1.5);
  }
  .list-item {
    box-shadow: 5px 5px 1px white, 5px 5px 1px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    transition: all 1s ease;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;

    .status i {
      font-size: 2rem;
      transition: all 1s ease;
      -webkit-transition: all 1s ease;
      -moz-transition: all 1s ease;
      -ms-transition: all 1s ease;
      -o-transition: all 1s ease;
    }
    > div {
      // box-sizing: border-box;
      display: flex;
      flex-direction: column;
      // min-width: 180px;
      width: 180px;
    }
    .select,
    .info {
      &.css-2b097c-container > div > div.css-g1d714-ValueContainer > div.css-1wa3eu0-placeholder {
        opacity: 0.5;
      }
    }
    padding: 0.5rem 1rem;

    &.red {
      &:hover {
        background-color: rgb(255, 71, 132) !important;
        color: white !important;
      }
      color: rgb(255, 71, 132) !important;
      background-color: rgba(255, 71, 132, 0.144) !important;
      .select {
        outline: rgb(255, 71, 132) !important;
        * {
          color: rgb(255, 71, 132) !important;
          // border: 1px solid;
          border: none;
        }
      }
    }
    &.green {
      color: #444;
      color: rgb(255, 145, 71);
      background-color: rgba(255, 145, 71, 0.068);
      &:hover {
        background-color: rgb(255, 145, 71);
        color: white;
      }
      .select {
        outline: rgb(255, 145, 71);
        * {
          color: rgb(255, 145, 71) !important;
          // border: 1px solid;
          border: none;
        }
      }
      // color: rgb(71, 255, 169);
    }
  }
}
